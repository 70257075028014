<template>
  <div id="NotFound">
    <div class="text-center mt-3">404 | Not Found</div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage',
  data() {
    return {}
  },
  created() {},
}
</script>

<style scoped></style>
